import React, { useContext, useEffect, useState } from 'react'
import { Card } from '@mui/material';
import { Divider } from '@mui/material';
import { JournalContext } from '../../Helper/Contex';

import { Link } from 'react-router-dom';

const AboutUs = () => {
    const { journals, indexings, topEditors } = useContext(JournalContext)
    return (
        <>
            <Card>
                <div className='m-2'>
                    <h3> About Us </h3>
                </div>
            </Card>
            <Divider />

            <Card>
                <div className='m-2'>
                    <p>
                        International Scientific and Academic Research Publisher is an international publisher committed to providing a ‘peer reviewed’ platform to outstanding researchers and scientists to exhibit their findings for the furtherance of society to provoke debate and provide an educational forum.
                    </p>
                    <p>
                        It's aim is to serve for the scholars and researchers all over the world by means of fast publishing and to get the widest exposure by providing free access. International Scientific and Academic Research Publishers team works with full dedication to improve the services and to maintain high quality standards.
                    </p>
                </div>


                <div className='m-2'>
                    <Card>
                        <div className='m-2'>
                            <h4>  Our Journal List </h4>
                        </div>
                    </Card>

                    <div>
                        <div className="row">
                            {journals && journals.map((j) => (
                                <div className="col-12 border border-primary">
                                    <div className='m-3'>
                                        <div>
                                            <b><Link to={`/journal/${j.slug}`}>{j.j_name} </Link> </b>
                                        </div>
                                        <div>
                                            <b> Journal starting year: </b>2023 
                                        </div>
                                        <div>
                                            <b> Frequency: </b>{j.frequency} 
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>



                </div>
            </Card>
        </>
    )
}

export default AboutUs;